





















































































































































































































































































.bg-dark-app {
  background-color: #303030;
}
.post-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.3;
}
.player-iframe {
  width: 100%;
  // height: calc(100vw / 3.2);
  height: 350px;
}
